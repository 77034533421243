<template>

  <Head>
    <Link v-if="banners1.length" rel="preload" fetchpriority="high" as="image" :href="`${useShopZone().value.staticResourcesRoot}/shopFiles/promos/${banners1[0].id}.webp`" type="image/webp" />
  </Head>
  <NuxtLayout name="catalog">
    <div class="page-default">
      <div class="page-container">

        <!-- banner block 1 -->

        <div class="flex flex-wrap gap-4 mb-4">
          <div class="flex flex-col gap-4 w-full desktop:flex-1">

            <!-- banner #1 -->

            <Carousel :autoplay="4000" :wrap-around="banners1.length > 1">
              <Slide v-for="(item, index) in banners1" :key="item.id">
                <AtomsTextLink class="w-full aspect-[2.5/1]" :to="item.url" :data-promotioncampaign="utils.cleanHtmlAttribute(item.campaign)" data-promotiontype="image" :data-promotionname="utils.cleanHtmlAttribute(item.title)">
                  <AtomsContainersCornerBorders class="aspect-[2.5/1]">
                    <AtomsImagesImage :src="`${useShopZone().value.staticResourcesRoot}/shopFiles/promos/${item.id}.jpg`" :webp="true" :disable-lazy="index === 0"></AtomsImagesImage>
                  </AtomsContainersCornerBorders>
                </AtomsTextLink>
              </Slide>
            </Carousel>

            <!-- usp -->

            <OrganismsBlocksUsps />
          </div>
          <div class="flex flex-wrap desktop:flex-col gap-4 w-full desktop:w-[32.7%]">

            <!-- video banner -->

            <AtomsTextLink v-if="videoBanner" :to="videoBanner.url" class="w-full tablet:flex-1 aspect-[2/1] overflow-hidden" style="container-type: inline-size;">
              <div class="corner-borders h-full outline-primary-500 p-4"></div>
              <!-- <img src="/img/logo.png" class="absolute left-8 top-8 w-[20%] opacity-50" alt="Rigad Logo" /> -->
              <video playsinline autoplay muted loop class="absolute left-0 top-0 right-0 bottom-0 -z-[1]">
                <source :src="`${useShopZone().value.staticResourcesRoot}/shopFiles/promos/${videoBanner.id}.mp4`" type="video/mp4">
                <source :src="`${useShopZone().value.staticResourcesRoot}/shopFiles/promos/${videoBanner.id}.webm`" type="video/webm">
              </video>
            </AtomsTextLink>

            <!-- banner #2 -->

            <AtomsTextLink v-if="banner2" :to="banner2.url" class="w-full tablet:flex-1" :data-promotioncampaign="utils.cleanHtmlAttribute(banner2.campaign)" data-promotiontype="image" :data-promotionname="utils.cleanHtmlAttribute(banner2.title)">
              <AtomsContainersCornerBorders class="aspect-[2/1]">
                <AtomsImagesImage :src="`${useShopZone().value.staticResourcesRoot}/shopFiles/promos/${banner2.id}.jpg`" :webp="true" :disable-lazy="true"></AtomsImagesImage>
              </AtomsContainersCornerBorders>
            </AtomsTextLink>
          </div>
        </div>

        <!-- banner block 2 -->

        <div class="mb-4 desktop:flex desktop:gap-4">
          <div class="flex flex-col gap-4 mb-4 tablet:flex-1 desktop:mb-0 desktop:order-2">

            <!-- tiles -->

            <div class="page-default__tiles">
              <AtomsTextLink :to="cache.getPageUrl(33210)">
                <AtomsImagesIcon icon="percent" />{{ $t('Služby') }}
              </AtomsTextLink>
              <AtomsTextLink :to="cache.getModuleUrl('modules/inspiration')">
                <AtomsImagesIcon icon="bulb" />{{ $t('Inspirace') }}
              </AtomsTextLink>
              <AtomsTextLink :to="cache.getModuleUrl('modules/thematic')">
                <AtomsImagesIcon icon="signpost" />{{ $t('Témata, aktivity') }}
              </AtomsTextLink>
            </div>

            <!-- social -->

            <AtomsContainersCornerBorders class="flex-1 p-4 bg-neutral-50" :small="true">
              <div class="page-default__social">
                <AtomsTextLink :to="appConfig.externalLinks.facebook" rel="nofollow" target="_blank">
                  <AtomsImagesIcon icon="facebook" size="3xl" /><span class="page-default__social__label">facebook</span>
                </AtomsTextLink>
                <AtomsTextLink :to="appConfig.externalLinks.youtube" rel="nofollow" target="_blank">
                  <AtomsImagesIcon icon="youtube" size="3xl" /><span class="page-default__social__label">youtube</span>
                </AtomsTextLink>
                <AtomsTextLink :to="appConfig.externalLinks.instagram" rel="nofollow" target="_blank">
                  <AtomsImagesIcon icon="instagram" size="3xl" /><span class="page-default__social__label">instagram</span>
                </AtomsTextLink>
              </div>
            </AtomsContainersCornerBorders>
          </div>

          <!-- banner #3 -->

          <AtomsTextLink v-if="banner3" :to="banner3.url" class="w-full tablet:flex-1" :data-promotioncampaign="utils.cleanHtmlAttribute(banner3.campaign)" data-promotiontype="image" :data-promotionname="utils.cleanHtmlAttribute(banner3.title)">
            <AtomsContainersCornerBorders class="aspect-[4/1]" :small="true">
              <AtomsImagesImage :src="`${useShopZone().value.staticResourcesRoot}/shopFiles/promos/${banner3.id}.jpg`" :webp="true"></AtomsImagesImage>
            </AtomsContainersCornerBorders>
          </AtomsTextLink>
        </div>

        <!-- header -->

        <h1 class="my-6 text-xl text-center uppercase font-bold tracking-tight tablet:text-3xl tablet:my-12">{{ page.header ?? page.title }}</h1>
      </div>

      <!-- new stuff -->

      <div class="py-6 bg-neutral-50">
        <div class="page-container">
          <AtomsTextHeading class="text-2xl">{{ $t('Nové zboží') }}</AtomsTextHeading>
          <OrganismsListsWareList :items="entity.wares.wares.items" type="slider" :with-slider-navigation="true" list-name="Nové zboží" class="mt-1 desktop:-mt-8 desktop:-mb-6"></OrganismsListsWareList>
        </div>
      </div>

      <!-- theme groups -->

      <TemplatesThemeGroups :carousel-mode="true" />

      <!-- vendors -->

      <div class="py-8 bg-neutral-50 desktop:pb-12">
        <div class="page-container text-center">
          <AtomsTextHeading>{{ $t('Nabízené značky a výrobci') }}</AtomsTextHeading>
          <div class="page-default__vendors">
            <div class="max-w-[1400px] max-h-[300px] mx-auto overflow-hidden">
              <AtomsTextLink v-for="vendor in vendors" :href="vendor.url" class="page-default__vendor" :style="`background-image: url('${useShopZone().value.staticResourcesRoot}/img/groups/vendors1/${vendor.group.id}.png');`"></AtomsTextLink>
            </div>
          </div>
          <MoleculesButtonsButton :ghost="true" :arrow-right="true" :href="cache.getPageUrl(28750)">{{ $t('Všechny značky') }}</MoleculesButtonsButton>
        </div>
      </div>

      <!-- videos & articles -->

      <div class="py-6">

        <div class="page-container">
          <AtomsTextHeading text-class="!bg-neutral-100">{{ $t('Videa a články') }}</AtomsTextHeading>
          <div class="page-default__videos-articles">
            <template v-for="(item, i) in Array.from({ length: 3 })">
              <div class="flex flex-col gap-4" :class="{ 'hidden tablet:flex': i === 1, 'hidden desktop:flex': i === 2 }">
                <MoleculesBlocksMagazineItem v-if="articles[i]" :with-button="true" :class="{ 'order-2': i === 1 }" :date-published="articles[i].page.publishedAt" :date-created="articles[i].created" :title="articles[i].page.title" :url="articles[i].page.url" :item-id="articles[i].id"></MoleculesBlocksMagazineItem>
                <MoleculesBlocksVideoItem v-if="videos?.[i]" :title="videos[i].title" :youtube-id="videos[i].youtubeId" :thumbnails="videos[i].thumbnails" :is-our-channel="videos[i].isOurChannel" />
              </div>
            </template>
          </div>
          <div class="flex flex-wrap justify-center tablet:justify-end gap-x-8 gap-y-4">
            <MoleculesButtonsButton :href="appConfig.externalLinks.youtube" :secondary="true" target="_blank" link-rel="nofollow" :arrow-right="true">{{ $t('Všechna videa na YouTube') }}</MoleculesButtonsButton>
            <MoleculesButtonsButton :href="cache.getPageUrl(18438)" :secondary="true" :arrow-right="true">{{ $t('Další články') }}</MoleculesButtonsButton>
          </div>
        </div>
      </div>

      <div class="bg-neutral-50 pt-1">

        <!-- subscribe -->

        <div class="page-default__subscribe" style="background-image: url(/img/bg-newsletter.jpg)">
          <div class="page-container text-center">
            <strong class="block mb-4 font-bold text-4xl text-neutral-50">{{ $t('Přihlaste se do newsletteru') }}</strong>
            <span class="block text-lg font-bold text-secondary-500">{{ $t('A mějte jako první informace o slevách, akcích a novinkách') }}</span>
            <div class="inline-flex flex-wrap justify-center gap-4 w-full mt-4 tablet:w-auto">
              <MoleculesFormsValidatedField type="email" v-model="subscriptionEmail" @submit="subscribeEmail" :required="true" class="flex-1 !my-0 text-left tablet:w-[600px]" wrapper-class="bg-neutral-50" input-class="leading-[1.85rem]" :error-message="$t('Vyplňte e-mail ve správném formátu')" :placeholder="i18n.t('Zadejte e-mail')" :ref="el => subscriptionInput = el" />
              <MoleculesButtonsButton @click="subscribeEmail" class="w-full self-start tablet:px-8 tablet:py-4 tablet:w-auto">{{ $t('Přihlásit') }}</MoleculesButtonsButton>
            </div>
          </div>
        </div>

      </div>

    </div>

  </NuxtLayout>
</template>
<script setup>

import { readBody } from 'h3';
import { Carousel, Slide, Navigation, Pagination } from '~/components/molecules/containers/carousel.js';
import "assets/css/carousel.css";
import { callWithNuxt } from 'nuxt/app';

const cache = useCache();
const appConfig = useAppConfig();
const locale = useLocale();
const i18n = useI18n();
const shopZone = useShopZone();
const entity = useEntity();
const utils = useUtils();
const page = usePage().value.page;
const app = useNuxtApp();

const transferedCartItems = useState('transferedCartItems', () => null);

if (process.server && useRequestEvent()?.method === 'POST') {

  try {

    const formData = (await readBody(useRequestEvent()));

    if (formData.transferForm) {
      const items = formData.items;
      const customerData = formData.customerData;
      const countryId = formData.countryId;

      if (items) {
        const itemsArray = JSON.parse(items);

        transferedCartItems.value = itemsArray;
      }

      if (customerData) {
        useCookie('customerData').value = JSON.parse(customerData);
      }

      if (countryId) {
        useCookie('selectedCountryId').value = countryId;
      }
    }
  }
  catch (e) {
    console.error(e);
  }
}

// cart transfer
if (process.client && transferedCartItems.value) {
  transferedCartItems.value.forEach(async (item) => {
    await useCart().add(item.vId, item.q, item.s);
  });
  transferedCartItems.value = null;
  onMounted(() => {
    useRouter().push(cache.getModuleUrl('modules/order/shippay'));
  });
}

useState('themeGroups').value = entity.themeGroups;

//#region promotions

const defaultPromotions = entity.promotionsList.promotions.items;

const banners1 = defaultPromotions.filter(promotion => promotion.idType === 1);
const banner2 = defaultPromotions.filter(promotion => promotion.idType === 2)[0];
const banner3 = defaultPromotions.filter(promotion => promotion.idType === 3)[0];
const videoBanner = defaultPromotions.filter(promotion => promotion.idType === 4)[0];

//#endregion

//#region vendors

const vendors = entity.vendors.items;

//#endregion

//#region videos & articles

const videos = entity.youtubeVideoList.youtubeVideos.items;

const articles = entity.infoPages.infoPages.items;

//#endregion

//#region subscribe

const subscriptionEmail = ref('');
const subscriptionInput = ref(null);

const subscribeEmail = async () => {

  if (await subscriptionInput.value.validate()) {
    await useApiFetch(`/api/mutations/newsletterSubscribe?email=${encodeURIComponent(subscriptionEmail.value)}&source=${appConfig.newsletterSubscribeSources.homePage}&currencyId=${locale.getLocale().currencyId}&languageId=${locale.getLocale().languageId}`);

    useBaseAnalytics().pushEvent('user_interaction', {
      interaction_name: 'newsletters_double_opt_in'
    });

    subscriptionEmail.value = '';
    subscriptionInput.value.reset();

    useMessage().show(i18n.t('E-mail byl zaznamenán, děkujeme'));
  }
  else {
    useMessage().show(i18n.t('Vyplňte e-mail ve správném formátu'), null, true, false, null, 3);
  }
}

//#endregion

//#region structured data

useHead({
  script: [{
    type: 'application/ld+json',
    innerHTML: JSON.stringify({
      '@context': 'https://schema.org',
      '@type': 'WebSite',
      'url': `https://${shopZone.value.domain}/`,
      'potentialAction': [{
        '@type': 'SearchAction',
        'target': {
          '@type': 'EntryPoint',
          'urlTemplate': `https://${shopZone.value.domain}${cache.getModuleUrl('modules/search')}#{search_term_string}`
        },
        'query-input': 'required name=search_term_string'
      }]
    })
  }],
  __dangerouslyDisableSanitizers: ['script'],
});

//#endregion

</script>
<style lang="postcss">
@keyframes theme-group-tile {
  0% {
    filter: grayscale(20%) brightness(40%) saturate(50%);
  }

  50% {
    filter: brightness(20%);
  }

  100% {
    filter: grayscale(20%) brightness(40%) saturate(50%);
  }
}

.page-default {
  @apply pt-4 bg-neutral-100;

  &__tiles {
    @apply flex-1 flex flex-wrap gap-[1px];

    a {
      @apply flex-1 flex flex-col gap-2 items-center justify-center p-4 font-bold text-center text-primary-50 bg-primary-500 transition-colors hover:no-underline hover:bg-primary-400;

      .icon {
        @apply w-full text-center;
      }
    }
  }

  &__social {
    @apply h-full flex gap-4 text-lg;

    a {
      @apply flex-1 flex gap-4 justify-center items-center font-bold text-primary-500 transition-colors hover:no-underline hover:text-primary-300;

      .page-default__social__label {
        @apply hidden tablet:block;
      }
    }
  }

  &__vendors {
    @apply relative my-8;
  }

  &__vendor {
    filter: grayscale(100%);
    @apply inline-block w-[160px] h-[60px] bg-center bg-no-repeat transition-all;

    &:hover {
      filter: grayscale(0%);
    }
  }

  &__videos-articles {
    @apply my-5 columns-1 gap-4 tablet:columns-2 desktop:columns-3;

    &>* {
      @apply mb-4;
      break-inside: avoid;
    }
  }

  &__subscribe {
    @apply py-12 bg-neutral-500 tracking-normal bg-center bg-cover;

    .validated-field__error {
      @apply text-neutral-50;
    }
  }
}
</style>